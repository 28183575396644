<script setup lang="ts">
import { SearchBar } from "@frontiers-vue3/public-pages";

export interface INewsSearchBarProps {
  placeholder?: string;
}

const props = withDefaults(defineProps<INewsSearchBarProps>(), {
  placeholder: "Search in all news"
});

const searchText = ref("");

const searchBarOnInput = (val: string): void => {
  searchText.value = val;
};

const searchFunc = (): Promise<boolean> => {
  return new Promise((resolve) => {
    if (searchText.value.trim().length < 3) return resolve(true);
    navigateTo(`/news/all?query=${searchText.value}`);
    resolve(true);
  });
};

const searchBarOnClear = (): void => {
  searchText.value = "";
};
</script>

<template>
  <SearchBar
    :value="searchText"
    :placeholder="placeholder"
    :min-chars-to-search="3"
    :max-chars-to-search="150"
    :search-func="searchFunc"
    :debounce-time="0"
    :disable-search-on-input="true"
    @input="searchBarOnInput"
    @clear="searchBarOnClear" />
</template>

<style>
.SearchBar__hint.SearchBar__hint {
  margin-left: 0;
}
</style>
